import {
  Box,
  Stack,
  Show,
  Flex,
  Hide,
  Button,
  Grid,
  GridItem,
  useDisclosure,
  useToast,
  useMediaQuery,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Text,
  AlertIcon,
  Alert,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  addSkuToInbound,
  cancelInbound,
  editInboundSKU,
  getInboundById,
  getInboundSKUs,
  removeInboundSKU,
  submitInboundForReview,
  updateInbound,
} from 'api/Dashboard/inbounds';
import { createSKU } from 'api/Dashboard/skus';
import BackToListButton from 'components/Dashboard/BackToListButton';
import CreateButtonsContainer from 'components/Dashboard/CreateButtonsContainer';
import AttachmentsSection from 'components/Dashboard/Orders/AttachmentsSection';
import SKUsTableForm from 'components/Dashboard/Orders/SKUsTableForm';
import TablePage from 'components/Dashboard/TablePage';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import useTable from 'hooks/useTable';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { adjustToUTC, renderDate } from 'utils/date';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import styled from '@emotion/styled';
import { flattenErrorObject } from 'utils/formError';
import SKUFormDrawer from 'components/Dashboard/SKUs/SKUFormDrawer';
import { AbilityContext } from 'context/AbilityContext';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { ReactComponent as SKUsEmptyTable } from 'assets/images/skus-empty-table.svg';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import { packagingEnum } from 'constants/dashboard';
import Select from 'components/Dashboard/Select';
import DebouncedNumberInput from 'components/shared/Inputs/DebouncedNumberInput';
import { skuQuantityValidation } from 'constants/validation';
import { packagingKeysMap } from 'components/Dashboard/Inbounds/SKUsSection';
import DatePicker from 'components/shared/Inputs/DatePicker';
import { useForm } from 'react-hook-form';

const StyledEditIcon = styled(EditIcon)`
  max-width: 20px;
  max-height: 20px;
  path {
    stroke: #fff;
  }

  path:last-of-type {
    stroke: #fff;
  }
`;

const columnHelper = createColumnHelper();
const InboundAssign = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const ability = useContext(AbilityContext);
  const canConfirm = ability.can('confirm', 'inbound');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  const [editItemID, setEditItemID] = useState(undefined);

  const { control, register } = useForm();

  const packagingOptions = Object.keys(packagingEnum).map((key) => ({
    label: t(key),
    value: packagingEnum[key],
  }));

  const fetchFn = useCallback(
    (searchParams) => getInboundSKUs(id, searchParams),
    [id]
  );

  const {
    data: skus = [],
    isLoading,
    onSearchChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    refetch: refetchSKUs,
  } = useTable({
    fetch: fetchFn,
    fetchKey: ['inbound-skus', id],
    searchKey: 'sku_merchant_sku_or_sku_system_sku_or_sku_name_cont',
    disableURLParams: true,
  });

  const {
    data: { data: inboundDetails = {} } = {},
    refetch: refetchInboundDetails,
  } = useQuery(['inbound-details', id], () => getInboundById(id));

  const { mutate: removeInboundSKUMutation, isLoading: isRemoveSKULoading } =
    useMutation((skuID) => removeInboundSKU({ id, skuID }), {
      onSuccess: (data) => {
        refetchSKUs();
      },
    });

  const { mutate: confirmInbound, isLoading: isConfirmInboundLoading } =
    useMutation(
      async () => {
        await updateInbound(id, {
          status: 'created',
        });
      },
      {
        onSuccess: () => {
          navigate(`/inbounds/${id}`);
        },
        onError: (error) => {
          const errorMsg = error.response.data.errors.status[0];

          toast({
            title: errorMsg,
            status: 'error',
            duration: 5000,
          });

          navigate(`/inbounds/${id}/edit`);
        },
      }
    );

  const { mutate: submitInbound, isLoading: isSubmitInboundLoading } =
    useMutation(() => submitInboundForReview(id), {
      onSuccess: () => {
        navigate(`/inbounds/${id}`);
      },
    });

  const { mutate: cancelInboundMutation, isLoading: isCancelInboundLoading } =
    useMutation(() => cancelInbound(id), {
      onSuccess: (success) => {
        navigate(`/inbounds/${id}`);
      },
    });

  const {
    data: createSKUData,
    mutate: createSKUMutation,
    isLoading: isCreateSKULoading,
    error: createSKUErrors,
  } = useMutation(createSKU, {
    onSuccess: (success) => {
      onClose();
    },
  });

  const { mutate: addSkuToInboundMutation, error: addSkuToInboundError } =
    useMutation((payload) => addSkuToInbound(id, payload), {
      onSuccess: (data) => {
        refetchSKUs();
      },
    });

  const { mutate: editInboundSKUMutation, isLoading: isEditInboundSKULoading } =
    useMutation(editInboundSKU, {
      onSuccess: (data) => {
        refetchSKUs();
      },
    });

  const handleSkuChange = (skuID, inbound_sku) => {
    if (inbound_sku.quantity === 0) {
      return;
    }

    const payload = {
      id,
      skuID,
      inbound_sku: {
        merchant_sku_packaging: inbound_sku.packaging,
        merchant_sku_quantity: inbound_sku.quantity,
        merchant_sku_expiry: inbound_sku.expiry_date,
      },
    };

    editInboundSKUMutation(payload);
  };

  const columns = [
    columnHelper.accessor('sku_name', {
      cell: ({ getValue }) => getValue(),
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
        fitContent: true,
      },
    }),
    columnHelper.accessor('expected.packaging', {
      cell: ({ getValue, row }) => {
        const {
          id,
          expected: { quantity, expiry_date },
        } = row.original;

        if (id === editItemID) {
          const value = getValue();

          const isPallet = value === 'Pallet wrap';

          const defaultValue = {
            label: t(value),
            value:
              packagingEnum[isPallet ? 'pallet' : value.toLocaleLowerCase()],
          };

          return (
            <Select
              placeholder={t('packagingType')}
              options={packagingOptions}
              onChange={({ value }) => {
                handleSkuChange(id, {
                  packaging: value,
                  quantity,
                  expiry_date,
                });
              }}
              isDisabled={isEditInboundSKULoading}
              defaultValue={defaultValue}
            />
          );
        }
        return (
          <Text fontWeight={500} textTransform="capitalize">
            {t(getValue())}
          </Text>
        );
      },
      header: t('expectedPackaging'),
    }),
    columnHelper.accessor('expected.quantity', {
      cell: ({ getValue, row }) => {
        const {
          id,
          expected: { packaging, expiry_date },
        } = row.original;

        if (id === editItemID) {
          const defaultValue = getValue();

          return (
            <DebouncedNumberInput
              placeholder="quantity"
              name="quantity"
              defaultValue={defaultValue}
              min={skuQuantityValidation.min}
              onChange={(value) => {
                const packagingKey = packagingKeysMap[packaging];

                handleSkuChange(id, {
                  packaging: packagingEnum[packagingKey],
                  quantity: value,
                  expiry_date: expiry_date,
                });
              }}
              isDisabled={isEditInboundSKULoading}
            />
          );
        }
        return <Text fontWeight={500}>{getValue()}</Text>;
      },
      header: t('expectedQuantity'),
      enableSorting: false,
    }),
    columnHelper.accessor('expected.expiry_date', {
      cell: ({ getValue, row }) => {
        const {
          id,
          expected: { packaging, quantity },
        } = row.original;

        if (id === editItemID) {
          const colValue = getValue();
          const defaultValue = colValue === 'N/A' ? undefined : colValue;

          return (
            <DatePicker
              name={`expiry_date.${id}`}
              register={register}
              errors={{}}
              defaultValue={defaultValue}
              control={control}
              placeholder={t('selectExpiryDate')}
              onChange={(value) => {
                const packagingKey = packagingKeysMap[packaging];

                handleSkuChange(id, {
                  packaging: packagingEnum[packagingKey],
                  quantity: quantity,
                  expiry_date: adjustToUTC(value),
                });
              }}
              isDisabled={isEditInboundSKULoading}
              appendPopperToParent
            />
          );
        }
        return <Text fontWeight={500}>{renderDate(getValue())}</Text>;
      },
      header: t('expectedExpiry'),
      enableSorting: false,
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        const id = row.original.id;

        const handleDelete = () => {
          removeInboundSKUMutation(row.original.id);
        };

        const handleEdit = () => {
          setEditItemID((prevValue) => (prevValue === id ? undefined : id));
        };

        if (isMobile) {
          return (
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<DotsVerticalIcon />}
                size="sm"
              />
              <MenuList>
                <MenuItem textTransform="capitalize" onClick={handleEdit}>
                  {t('edit')}
                </MenuItem>
                <MenuItem
                  textTransform="capitalize"
                  color="red.500"
                  onClick={handleDelete}
                >
                  {t('remove')}
                </MenuItem>
              </MenuList>
            </Menu>
          );
        }

        return (
          <fieldset disabled={isRemoveSKULoading ? 'disabled' : undefined}>
            <Flex gap={3} justifyContent="center">
              <Button
                variant="outline"
                color="gray.600"
                textTransform="capitalize"
                fontWeight={500}
                onClick={handleEdit}
              >
                {t('edit')}
              </Button>
              <Button
                variant="outline"
                color="red.600"
                textTransform="capitalize"
                fontWeight={500}
                onClick={handleDelete}
              >
                {t('remove')}
              </Button>
            </Flex>
          </fieldset>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
        centerHeader: true,
      },
      enableSorting: false,
    }),
  ];

  const handleSkuSubmit = (sku, onSuccessCallback) => {
    addSkuToInboundMutation(
      {
        ...sku,
        expiry_date: adjustToUTC(sku.expiry_date),
      },
      {
        onSuccess: onSuccessCallback,
      }
    );
  };

  return (
    <Stack spacing={6} pb={16}>
      <Box>
        <Show below="md">
          <Box mb={2} mt={4}>
            <PageTitle title={pageTitle} fontWeight={600} />
          </Box>
        </Show>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Hide below="md">
            <BackToListButton
              title={t('inboundDetails')}
              url={`/inbounds/${id}/edit`}
            />
          </Hide>
        </Flex>
      </Box>
      <TablePage
        data={skus}
        columns={columns}
        pagination={pagination}
        title={<SectionTitle title={t('inboundSKUs')} hideDivider />}
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        searchPlaceholder={t('skusPages.searchPlaceholder')}
        onSearchChange={onSearchChange}
        isLoading={isLoading}
        tableAction={
          <>
            <Button
              leftIcon={<StyledEditIcon />}
              colorScheme="primary"
              textTransform="capitalize"
              size="lg"
              onClick={onOpen}
            >
              {t('skusPages.createNewSKU')}
            </Button>
            <SKUFormDrawer
              isOpen={isOpen}
              onClose={onClose}
              backendErrors={flattenErrorObject(
                createSKUErrors?.response?.data?.errors
              )}
              isLoading={isCreateSKULoading}
              onSubmit={(payload) => createSKUMutation(payload)}
            />
          </>
        }
        footer={
          <Box pb={6} mt={2}>
            {!isCreateSKULoading && (
              <SKUsTableForm
                onSubmit={handleSkuSubmit}
                defaultValue={{
                  sku_id: createSKUData?.data.id,
                }}
                backendErrors={addSkuToInboundError?.response?.data?.errors}
              />
            )}
          </Box>
        }
        emptyTable={
          <EmptyTable
            illustration={<SKUsEmptyTable />}
            title={t('noSKUsYet')}
            subtitle={`${t('createNewSKU')} ${t('or')} ${t('selectExisting', {
              value: t('theInbound'),
            })}`}
            noPadding
          />
        }
        showEmptyInsideBody
      />

      {!isLoading && (
        <AttachmentsSection
          id={id}
          attachments={inboundDetails.attachments}
          onSuccess={refetchInboundDetails}
          attachmentType="Manifest"
          disclaimer={
            <Alert status="warning" borderRadius="xl" alignItems="start">
              <AlertIcon />

              <Stack spacing={0} color="gray.700">
                <Text>
                  <Text as="span" fontWeight="bold" marginInlineEnd={1}>
                    {t('pleaseNoteForInternationalShipments')}
                  </Text>

                  {t('ifAnyDocumentNotAttached')}
                </Text>

                <UnorderedList>
                  <ListItem>{t('customsDeclaration')}</ListItem>
                  <ListItem>{t('billOfEntry')}</ListItem>
                  <ListItem>{t('pickingListOrInvoiceOfGoods')}</ListItem>
                </UnorderedList>
              </Stack>
            </Alert>
          }
        />
      )}

      <CreateButtonsContainer>
        <Box
          as="fieldset"
          disabled={
            isCancelInboundLoading || isConfirmInboundLoading
              ? 'disabled'
              : undefined
          }
          flex={1}
        >
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 3, md: 2 }}>
              <Button
                colorScheme="gray"
                variant="outline"
                bgColor="white"
                size="lg"
                minWidth="124px"
                width="full"
                as={Link}
                to={`/inbounds/${id}`}
              >
                {t('saveDraft')}
              </Button>
            </GridItem>
            <GridItem colSpan={{ base: 3, md: 2 }}>
              <Button
                colorScheme="red"
                variant="outline"
                bgColor="white"
                size="lg"
                minWidth="124px"
                width="full"
                onClick={cancelInboundMutation}
                isLoading={isCancelInboundLoading}
              >
                {t('cancelInbound')}
              </Button>
            </GridItem>

            <GridItem colSpan={{ base: 6, md: 2 }}>
              <Button
                colorScheme="primary"
                size="lg"
                textTransform="capitalize"
                minWidth="124px"
                width="full"
                onClick={canConfirm ? confirmInbound : submitInbound}
                isLoading={
                  canConfirm ? isConfirmInboundLoading : isSubmitInboundLoading
                }
                isDisabled={skus.length === 0}
              >
                {t(canConfirm ? 'confirm' : 'submitForReview')}
              </Button>
            </GridItem>
          </Grid>
        </Box>
      </CreateButtonsContainer>
    </Stack>
  );
};

export default InboundAssign;
